.checkbox-wrapper input[type='checkbox'] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  display: flex;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  margin-right: 0.5em;
  border: 0.08em solid #9d9d9d;
  outline: none;
  cursor: pointer;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.intusui-checkbox-checked {
  background-color: #2e62e7;
  position: relative;
  color: #fff;
}

.intusui-checkbox-checked::before {
  content: url(./check.svg);
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
}

/* checkbox hover styles */
.checkbox-wrapper input[type='checkbox']:checked:hover {
  background-color: #052d8f;
  color: #fff;
}

.checkbox-wrapper input[type='checkbox']:not(:checked):hover {
  background-color: #4d7ef829;
  color: #fff;
}

/* checkbox focus styles */
.checkbox-wrapper input[type='checkbox']:checked:focus-visible {
  background-color: #052d8f;
  color: #fff;
}

.checkbox-wrapper input[type='checkbox']:not(:checked):focus-visible {
  background-color: #4d7ef829;
  color: #fff;
}

/* checkbox disabled styles */
.checkbox-wrapper input[type='checkbox']:checked:disabled {
  background-color: #eff0f3;
  border: 0.15em solid #9d9d9d;
  color: #fff;
}

.checkbox-wrapper input[type='checkbox']:not(:checked):disabled {
  background-color: #c8c8c8;
  border: 0.15em solid #9d9d9d;
  color: #fff;
}
