@import './Palette.scss';

.box-shadow-navbar {
  box-shadow: 0px 2px 10px $box-shadow;
}

.god-himself {
  opacity: 0;
  width: 60px;
  padding-left: 5px;
}

.god-himself:hover {
  opacity: 1;
}

.help-icon {
  z-index: 99;
  position: absolute;
  bottom: 1.1em;
  right: 1.1em;
  width: 3em;
  height: 3em;
  cursor: pointer;
  border: 0;
  background-color: rgba(148, 200, 249, 1);
  color: white;
  outline: none !important;
  transition: background-color 1s ease;
}

.help-icon:hover {
  background-color: rgb(108, 181, 249);
}
