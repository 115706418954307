.searchinput-container {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  border: solid 1px #9d9d9d;
  height: 35px;
  maxwidth: 350px;
  align-items: center;
  padding: 0px 15px 0px 15px;
  gap: 10px;
}

.searchinput-container:hover {
  background-color: #e4ecff;
}
