@import './Palette.scss';

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 3em;
  height: 1.5em;
  background: $intus-blue;
  border-radius: 0.75em;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 0.2em;
  left: 0.25em;
  width: 1.1em;
  height: 1.1em;
  border-radius: 0.75em;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 0.2em);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 2.6em;
}
