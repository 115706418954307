@import '@intus-ui/styles/IntusColors.scss';

// Default button styling --- shared across all buttons
.default-button {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  padding: 0 15px;
  height: 35px;
  font-weight: bold;
  font-size: 15px;
  line-height: initial;
  border: none;
  min-width: 120px;
  line-height: 'initial';

  &.withIcon,
  &.compact {
    min-width: unset;
    padding: 0 7px;
  }

  &.fluid {
    width: 100%;
  }

  svg {
    height: 22px;
    width: 22px;
  }
}

.boldText {
  font-weight: 700;
}

// Primary button styling --- matches the primary button in style guide
.primary-button {
  background-color: $primary;
  color: $background;
}

.primary-button:hover {
  background-color: $primary-hover;
}

.primary-button:focus,
.primary-button:active {
  outline: 2px solid $background;
  outline-offset: -4px;
}

.primary-button:disabled {
  opacity: 0.5;
  background-color: $disable;
  color: $disabled-text;
  &:hover,
  &:focus,
  &:active {
    outline: 2px solid transparent;
  }
}

// Secondary button styling --- matches the secondary button in style guide
.secondary-button {
  color: $primary;
  background-color: transparent;
  border: 1.5px solid $primary;
}

.secondary-button:hover:not(:disabled) {
  background-color: $secondary;
  border: 1.5px solid $primary;
}

.secondary-button:focus,
.secondary-button:active {
  outline: 1.5px solid $primary;
  outline-offset: -4px;
  border: 1.5px solid $primary;
}

.secondary-button:disabled {
  border: 1.5px solid $disabled-text;
  color: $disabled-text;
  opacity: 0.5;

  &:hover,
  &:focus,
  &:active {
    outline: 2px solid transparent;
  }
}

// Text button styling and tab button styling are mostly identical
// Tab button styling adds an "active" state
.textButton,
.tabButton {
  color: $primary;
  background-color: $transparent;
  outline: 2px solid transparent;
}

.textButton:hover,
.tabButton:hover {
  color: $primary;
  background-color: $secondary;
}

.textButton:disabled,
.tabButton:disabled {
  color: $disabled-text;
  opacity: 0.5;

  &:hover,
  &:focus,
  &:active {
    outline: 2px solid transparent;
    background-color: transparent;
  }
}

.textButton:focus,
.tabButton:focus {
  outline: 2px solid $secondary-hover;
}

.tabButton:active,
.tabButton.active {
  color: $textButton-hover;
  background-color: $surface-dark;

  &:focus {
    outline: 2px solid $secondary-hover;
    outline-offset: -4px;
  }
}
