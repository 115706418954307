@import './Palette.scss';

.outlined-list-item {
  border: 1px solid $intus-gray;
  transition: 0.25s ease;
  color: $intus-dark-gray;

  // &:focus {
  //   border: 1px solid $intus-blue;
  //   background-color: $off-white;
  // }

  // &:hover {
  //   border: 1px solid $intus-blue;
  // }
}

.list-item {
  border: 2px solid transparent;
  transition: 0.25s ease;
  background-color: $white;

  &:active {
    border: 2px solid $light-blue-hover;
    color: $intus-dark-gray;
    background-color: $off-white-blue;
  }

  &:hover {
    color: $intus-dark-gray;
    background-color: $off-white-blue;
  }
}

.list-item :hover {
  border: 0px solid transparent;
}

.button {
  border: 0px solid transparent;
}

.button:hover {
  border: 3px solid $intus-blue;
  background-color: $intus-blue;
}
