.controls {
  margin-top: 1rem;
}

i {
  font-size: 16px;
}

a {
  font-family: "Inter", sans-serif;
}

.action-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: "Inter", sans-serif;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  width: 7rem;
  border-radius: 10px;
  background-color: #2e62e7;
  border-width: 0px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  transition: 50ms;
  margin-bottom: 0.25rem;
}

.action-button:hover {
  background-color: #6b92f4;
}

.action-button:focus {
  outline: none;
}

.action-button:disabled {
  background-color: #6b92f4;
}
