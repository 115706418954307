@import './Palette.scss';

.blue-background {
  background-color: $intus-blue !important;
}

// This supplements the Bootstrap container to make it smaller.
@media (min-width: 768px) {
  .container-small {
    width: 400px !important;
  }
}

@media (min-width: 992px) {
  .container-small {
    width: 500px !important;
  }
}

@media (min-width: 1200px) {
  .container-small {
    width: 600px !important;
  }
}

.container-small {
  max-width: 100%;
}

.logo {
  width: 10rem;
}

.logo-small {
  width: 4rem;
}

.care-text {
  font-weight: bold;
  color: $intus-blue;
}

.hover-box {
  box-shadow: 0px 0.2rem 1.2rem rgba($color: #000000, $alpha: 0.15);
  border-radius: 1rem !important;
}

.dropDownBox {
  background-color: gray;
}

.button-position {
  align-items: flex-end;
}
