.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height: 100%;
    overflow: hidden;
}

.chatRoot {
    flex: 1;
    display: flex;
    height: 100%;
    overflow: hidden;
}

.chatContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.chatEmptyState {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 1024px;
    padding-top: 60px;
}

.chatEmptyStateTitle {
    font-size: 4rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 30px;
}

.chatEmptyStateSubtitle {
    font-weight: 600;
    margin-bottom: 10px;
}

@media only screen and (max-height: 780px) {
    .chatEmptyState {
        padding-top: 0;
    }

    .chatEmptyStateTitle {
        font-size: 3rem;
        margin-bottom: 0px;
    }
}

.chatMessageStream {
    flex-grow: 1;
    max-height: 1024px;
    width: 100%;
    overflow-y: auto;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chatMessageGpt {
    margin-bottom: 20px;
    max-width: 80%;
    display: flex;
    min-width: 500px;
}

.chatMessageGptMinWidth {
    max-width: 500px;
    margin-bottom: 20px;
}

.chatInput {
    flex: 0 0 100px;
    padding-top: 12px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 1028px;
}

.messagesGoHere {
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
}

.chatAnalysisPanel {
    flex: 1;
    overflow-y: auto;
    max-height: 89vh;
    margin-left: 20px;
    margin-right: 20px;
}

.chatSettingsSeparator {
    margin-top: 15px;
}

.loadingLogo {
    font-size: 28px;
}

.commandsContainer {
    display: flex;
    align-self: flex-end;
}

.commandButton {
    margin-right: 20px;
    margin-bottom: 20px;
}
