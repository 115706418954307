@keyframes openMenu {
  0% {
    clip-path: inset(0px 100% 0px 0px);
    width: 400px;
  }
  100% {
    clip-path: inset(0px 0px 0px 0px);
    width: 400px;
  }
}

@keyframes closeMenu {
  0% {
    clip-path: inset(0px 0px 0px 0px);
    width: 400px;
  }
  100% {
    clip-path: inset(0px 100% 0px 0px);
    width: 400px;
  }
}

.open-hamburger-menu {
  animation-name: openMenu;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

.close-hamburger-menu {
  animation-name: closeMenu;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

@keyframes showOverlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes hideOverlay {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

.show-overlay {
  animation-name: showOverlay;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

.hide-overlay {
  animation-name: hideOverlay;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
