.card-container {
  height: 100%;
}

.card {
  box-shadow: 0 5px 15px lightgrey;
  padding: 1rem;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 20% 40% 40%;
  margin-bottom: 1rem;
}

.text-wrap {
  word-wrap: break-word;
  word-break: break-all;
}

/* button {
  color: white;
} */
