$base-color: #ffffff;
$white: #ffffff;
$black: black;
$accent-color: #f5f5f5;
$intus-blue: #4d7ef8;
$intus-gray: #97999b;
$intus-dark-gray: #404040;
$intus-blue-30: rgba(77, 126, 248, 0.3);

$intus-navy: #052d8f;
$intus-med-navy: #194bc3;
$intus-navy-40: rgba(5, 45, 143, 0.4);
$intus-navy-60: rgba(5, 45, 143, 0.6);
$intus-navy-t-40: #80a0ed;
$intus-navy-t-50: #5b83e3;
$intus-navy-t-60: #3967d6;
$box-shadow: rgba(0, 0, 0, 0.05);
$box-shadow-dark: rgba(0, 0, 0, 0.14);
$er-visit-orange: #c97513;
$off-white-blue: #f6f7fa;
$risk-gray: #aaaaaa;
$risk-red: #cc6262;
$risk-yellow: #e4c20f;
$risk-blue: #94c8f9;
$risk-blue-4: rgba(148, 200, 249, 0.4);
$risk-blue-9: rgba(148, 200, 249, 0.9);
$risk-blue-tint-20: #ebf6ff;
$selected-blue: #f0f8ff;
$green: #7ace46;
$red: #ea4040;
$red-hover: #d43b3b;

$transparent: rgba(0, 0, 0, 0);

$light-blue-hover: rgba(77, 126, 248, 0.4);

//GRAYSCALE
$black: #000000;
$body: #626275;
$label: #7d7d87;
$placeholder: #97999b;
$line: #c9cdd6;
$input-back: #f1f2f3;
$off-white: #f6f7fa;

//Intus-light-blue
$light-blue: #94c8f9;
$light-blue-t-300: #ebf6ff;
$light-blue-t-400: #dff0ff;
$light-blue-t-500: #cee8ff;
$light-blue-t-600: #c0e1ff;
$light-blue-t-700: #b2daff;

//White transp
$white: #ffffffff;
$white-op-30: rgba(255, 255, 255, 0.3);
$white-op-50: rgba(255, 255, 255, 0.5);
$white-op-90: rgba(255, 255, 255, 0.9);

@function intus-blue-transparent($transparency) {
  @return rgba(77, 126, 248, $transparency);
}
