@import './Palette.scss';

.buttonPosition {
  position: relative;
  right: 17px;
  top: 50px;
}

.buttonPosition2 {
  position: relative;
  right: 0px;
  left: 333px;
  top: 50px;
}

.notificationBackButtonPosition {
  position: relative;
  right: 150px;
  top: 5px;
}

.notificationNextButtonPosition {
  position: relative;
  left: 150px;
  top: 5px;
}

.rounded-xl {
  border-radius: 0.6em;
}

.selection-button {
  background-color: $white !important;
  color: $intus-dark-gray !important;
}

// .modal-button:hover {
//   color: yellow;
//   background: yellow !important;
//   background-color: yellow !important;
// }

.selection-button:hover {
  background: $white !important;
  color: $intus-dark-gray !important;
  outline: 0 !important;
  border: 1px $intus-blue-30 solid !important;
}

.selection-button:active {
  background: $white !important;
  color: $intus-dark-gray !important;
  outline: 0 !important;
  border: 1px $intus-blue-30 solid !important;
}

.selection-button:focus {
  background: $white !important;
  color: $intus-dark-gray !important;
  outline: 0 !important;
  border: 1px $intus-blue-30 solid !important;
}

.selection-button:disabled {
  background: $off-white-blue !important;
  color: $intus-dark-gray !important;
  outline: 0 !important;
  cursor: default !important;
}

.modal-back-button:hover {
  background: $white !important;
  color: $intus-navy !important;
  outline: 0;
  border: 1px $intus-blue-30 solid;
}

.modal-back-button:active {
  background: $white !important;
  color: $intus-navy !important;
  outline: 0;
  border: 1px $intus-blue-30 solid;
}

.modal-back-button:focus {
  background: $white !important;
  color: $intus-navy !important;
  outline: 0;
  border: 1px $intus-blue-30 solid;
}

.modal-back-button:disabled {
  background: $off-white-blue !important;
  color: $intus-blue-30 !important;
  outline: 0;
  cursor: default;
}
