@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html,
body {
  margin: 0;
  height: 100%;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Used for fluid text previously: */
/* html { */
/* font-size: calc(0.6em + 0.5vw); */
/* font-size: calc(16px + (22 - 16) * (100vw - 400px) / (1700 - 400)); */
/* font-size: calc(16px + 0 * ((100vw - 600px) / 1100)); */
/* } */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  display: inline;
  padding: 0;
  margin: 0;
  font-family: 'Inter';
  letter-spacing: -1%;
  line-height: 130%;
}

h1 {
  font-weight: 600;
  font-size: 4.8rem;
}

h2 {
  font-weight: 700;
  font-size: 1.4rem;
}

h3 {
  font-weight: 700;
  font-size: 1.2rem;
}

h4 {
  font-weight: 400;
  font-size: 1.2rem;
}

h5 {
  font-weight: 600;
  font-size: 1rem;
}

h6 {
  font-weight: 400;
  font-size: 1rem;
}

p {
  font-weight: 400;
  font-size: 0.8rem;
}

.App {
  font-family: 'Inter', Arial, sans-serif;
}
