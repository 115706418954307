@import './Palette.scss';

.flex-column {
  display: flex;
  flex-direction: column;
}

.editIcon {
  position: relative;
  left: 80px !important;
}

.editIcon2 {
  position: relative;
  left: 140px !important;
}

.buttonClear {
  position: relative;
  left: 180px !important;
}
