.event-list-item {
  display: flex;
  flex-direction: row;
  min-height: auto;
  border-radius: 5px;
  justify-content: center;
  border: 1px solid #c8c8c8;
  align-items: center;
  padding: 8px 15px;

  &:hover {
    border: 1px solid #2e62e7;
    cursor: pointer;
    background-color: #eff0f3;
  }
}
