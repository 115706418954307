input.pickerInput[type='text'] {
  outline-style: 0.45px;
  cursor: pointer;
  line-height: 2.5em;
  border: 0.5px white solid;
  background-color: white;
  width: 100%;
  color: #849095;
  text-align: center;
  border-radius: 4px;
  font-size: 0.8em;
  -webkit-box-shadow: -0px 1.5px 1px 0px rgba(203, 209, 215, 0.8);
  -moz-box-shadow: -0px 1.5px 1px 0px rgba(203, 209, 215, 0.8);
  box-shadow: -0px 1.5px 1px 0px rgba(203, 209, 215, 0.8);
}

input.pickerInput[type='text']:disabled {
  background-color: white;
}
