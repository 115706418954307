@import './IntusColors.scss';

.event-badge {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px 3px 10px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.5px;
  border-radius: 15px;

  &.event-badge-enrolled {
    background-color: #ffffff;
    color: #94c8f9;
    border: 1px solid #94c8f9;
  }
  &.event-badge-disenrolled {
    background-color: white;
    color: #9d9d9d;
    border: 1px solid #9d9d9d;
  }
  &.event-badge-admit {
    background-color: white;
    color: #eac505;
    border: 1px solid #eac505;
  }
  &.event-badge-discharge {
    background-color: white;
    color: #eac505;
    border: 1px solid #eac505;
  }
  &.event-badge-er {
    background-color: white;
    color: #be792f;
    border: 1px solid #be792f;
  }
  &.event-badge-inpatient {
    background-color: white;
    color: #eac505;
    border: 1px solid #eac505;
  }
  &.event-badge-discharged {
    background-color: white;
    color: #eac505;
    border: 1px solid #eac505;
  }
}

.risk-badge {
  min-width: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  border-radius: 15px;

  &.risk-badge-highest {
    background-color: $highest-risk;
    color: white;
  }

  &.risk-badge-high {
    background-color: $high-risk;
    color: $body;
  }

  &.risk-badge-moderate {
    background-color: $moderate-risk;
    color: white;
  }

  &.risk-badge-low {
    background-color: $low-risk;
    color: $body;
  }

  &.risk-badge-insuff,
  &.risk-badge-insufficient {
    background-color: $insuficient-data-risk;
    color: $body;
  }

  &.risk-badge-all {
    background-color: white;
    color: $body;
    border: 2px solid #baceff;
  }

  &.risk-badge-disenrolled {
    background-color: #C8C8C8;
    color: white;
    border: 2px solid #C8C8C8;
  }
}
