.questionInputContainer {
    border-radius: 20px;
    box-shadow:
        0px 8px 16px rgba(200, 200, 200, .5),
        0px 0px 2px rgba(200, 200, 200, 1);

    height: 100%;
    width: 100%;
    padding: 15px;

}

.questionInputTextArea {
    width: 100%;
    line-height: 40px;
}

.questionInputButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
