// Overrides the default colors.
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import './Palette.scss';

// Do not change the theme colors to something that works differently.
// For example, don't change "dark" to be transparent or light gray.

$theme-colors: (
  'primary': $intus-blue,
  'secondary': $intus-navy,
  'secondary-hover': $intus-navy-40,
  'secondary-button': rgba(77, 126, 248, 0.16),
  'dark': $intus-dark-gray,
  'muted': $intus-gray,
  'danger': $risk-red,
  'info': $risk-blue,
  'info-tint-20': $risk-blue-tint-20,
  'warning': $risk-yellow,
  'success': $green,
  'off-white': $off-white-blue,
  'transparent': $transparent,
  'light': $line,
  'black': $black,
  'white': $white,
  'white-30': $white-op-30,
);

@import '../../../node_modules/bootstrap';

.input {
  width: 2em;
  height: 2em;
  border-radius: 0.5em;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $intus-navy;
  font-weight: 600;
  border: 4;
}

.nav-tabs {
  border-bottom: none;
  flex-wrap: none;
}

.text-muted {
  color: $intus-gray !important;
}

.btn-sm {
  padding: 0.2em 0.6em;
  font-size: 0.7em;
  background-color: $white;
  border: 1px solid $intus-blue;
  color: $intus-blue;
}

// Overrides bootstrap badge style
.badge {
  padding: 0.4em 0.6em;
  // color: $base-color;
}

// Overrides bootstrap tooltip style
.tooltip-inner {
  background-color: $base-color !important;
  border: 2px solid $intus-blue;
  border-radius: 10px;
  box-shadow: 0px 0px 0.4rem 0.06rem $box-shadow-dark;
  color: $intus-blue;
}

.tooltip.show {
  opacity: 1;
}

// Overrides the navbar style.
// Styles that don't have to be overridden via Bootstrap modifications are in IntusNavBar.scss.
.navbar-light .navbar-brand {
  &,
  &:hover {
    color: $intus-gray;
  }
}

// overrides bootstrap card styling
.card {
  border-radius: 18px !important;
}

// overrides bootstrap button styling
.btn:not(.rounded-left):not(.rounded-right) {
  border-radius: 10px !important;
}

.bg-light {
  background-color: $off-white-blue !important;
}

.form-label {
  font-size: 0.7em;
  color: $intus-dark-gray;
}

.form-check-fluid {
  cursor: pointer !important;

  input,
  label {
    cursor: pointer !important;
  }

  label {
    width: 100%;
  }
}

.rounded-lg {
  border-radius: 10px !important;
}

.rounded-xlg {
  border-radius: 20px !important;
}

// Overriding bootstrap modal default styling
.modal-dialog {
  max-width: 45vw;
}

.modal-content {
  border-radius: 2rem;
}

.modal-title {
  text-align: center;
  width: 100%;
}

.modal-header {
  color: $intus-blue;
  border: none;
  padding: 2rem;
}

.modal-body {
  margin: 0rem 4rem 0.2rem;

  button {
    margin: 0.8em 0em 1.8em;
  }
}

.dropdownMenu-scrollable {
  .dropdown-menu.show {
    max-height: 250px;
    overflow-y: auto;
  }
}

// Makes the app container 100% height.
.App,
#root,
body,
html {
  height: 100%;
}
