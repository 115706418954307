@import './Palette.scss';

.list-nav-link {
  padding: 0;
  color: $intus-navy;
}

.list-nav-link :hover {
  background-color: $light-blue-hover;
}

.text-bar {
  border-width: '4px';
  border-left-style: solid;
  border-color: $intus-navy;
}

.text-bar :hover {
  border-color: $intus-blue;
}

.font-weight-semibold {
  font-weight: 500;
}

.text-sm-p {
  font-size: 0.9em;
}

.text-xs-p {
  font-size: 0.8em;
}

.text-icon {
  font-size: 0.9rem;
}

.neg-horiz-margin {
  margin-left: -1.6em;
  margin-right: -1.6em;
}

.text-navy-60 {
  color: #3967d6;
}

.bg-input {
  background-color: $input-back;
}

.cursor-pointer {
  cursor: pointer;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 0;
}

.keep-on-one-line {
  white-space: nowrap;
}

.off-white-background {
  background-color: $off-white-blue;
}

.user-select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltip-text {
  font-size: 0.7em;
}

.container-side-padding-md {
  padding: 0px 100px;
}

.container-side-padding-sm {
  padding: 0em 1em;
}

.container-side-margin-sm {
  margin: 0em 1em;
}

.content-center {
  vertical-align: middle;
  height: 100%;
}

.height-95 {
  height: 95%;
}

.height-90 {
  height: 90%;
}

.height-85 {
  height: 85%;
}

.height-80 {
  height: 80%;
}

.height-60 {
  height: 60%;
}

.height-40 {
  height: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.vertical-align-text {
  vertical-align: middle;
  display: inline;
}

.search-bar {
  border-radius: 20px;
}

.info-icon {
  padding: 0.2rem;
  color: $intus-blue;
}

.info-icon :hover {
  color: $intus-navy;
}

.text-align-end {
  text-align: end;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.tab-border {
  border-top: 1px solid $intus-dark-gray;
  border-left: 1px solid $intus-dark-gray;
  border-right: 1px solid $intus-dark-gray;
}

.select-border {
  border: 2px solid $intus-blue-30;
  border-radius: 3rem;
}

.box-shadow {
  box-shadow: 0px 0.4px 0.4rem 0.06rem $box-shadow;
}

.box-shadow-on-gray {
  box-shadow: 0px 0.1rem 0.8rem 0.1rem $box-shadow-dark;
}

.box-shadow-on-blue {
  box-shadow: 0px 0.1rem 0.8rem 0.1rem $risk-blue;
}

.box-shadow-on-blue:hover {
  box-shadow: 0px 0.1rem 0.8rem 0.1rem $risk-blue;
}

.box-shadow-cards {
  box-shadow: 0px 0.1rem 0.4rem 0.06rem $box-shadow-dark;
}

.outlined-tab {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 1px solid $intus-gray;
  border-left: 1px solid $intus-gray;
  border-right: 1px solid $intus-gray;
  overflow: hidden;
  // padding-bottom: 0.3rem;

  &.outlined-bottom {
    // border-bottom: 1px solid $intus-gray;
    padding-bottom: 0;
  }

  & > a:first-child {
    // border-top-right-radius: 0;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    // border-color: $intus-gray;
    // border-top: 1px solid $intus-gray;
    // border-left: 1px solid $intus-gray;
    // border-right: 1px solid $intus-gray;
  }

  // &>a:last-child {
  //   border-top-left-radius: 0;
  //   border-top-left-radius: 10px;
  //   border-top-right-radius: 10px;
  //   border-color: $intus-gray;

  //   // border-top: 1px solid $intus-gray;
  //   // border-left: 1px solid $intus-gray;
  //   // border-right: 1px solid $intus-gray;
  // }

  & > a:not(.active) {
    background-color: #eff0f3;
    // border-color: $intus-gray;

    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    // border-top: 1px solid $intus-gray;
    // border-left: 1px solid $intus-gray;
    // border-right: 1px solid $intus-gray;
  }

  &a.active {
    // border-color: $intus-gray;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    // border-top: 1px solid $intus-gray;
    // border-left: 1px solid $intus-gray;
    // border-right: 1px solid $intus-gray;
  }

  & > a.active:hover {
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    // border-top: 1px solid $intus-gray;
    // border-left: 1px solid $intus-gray;
    // border-right: 1px solid $intus-gray;
    cursor: default;
  }

  & > a:hover {
    // background-color: $light-blue-t-600;
    color: $intus-navy;
    // border-color: $intus-gray;

    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    // border-top: 1px solid $intus-gray;
    // border-left: 1px solid $intus-gray;
    // border-right: 1px solid $intus-gray;
  }
}

.message-icon {
  color: $intus-navy;
  font-size: 0.7em;
  text-align: center;
  padding: 5px;
}

.med-lg-text {
  font-size: 2.2em;
}

.smaller-text {
  font-size: 0.8em;
}

.smallest-text {
  font-size: 0.7em;
}

.h6-body {
  font-size: 15px;
}

.transparent {
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  width: 0px;
}

.transparent:focus {
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  width: 0px;
}

.hover-light:hover {
  background-color: $off-white-blue !important;
}

// .hover-lighter:hover {
//   background-color: #f6fafe !important;
// }

.bg-transparent-90 {
  background-color: $white-op-90;
}

.bg-transparent-50 {
  background-color: $white-op-50;
}

.selected-bg {
  background-color: $selected-blue;
  border: 2px solid $risk-blue;
}

.min-w-90 {
  min-width: 90%;
}

.min-w-70 {
  min-width: 70%;
}

.min-w-50 {
  min-width: 50%;
}

.smoother {
  transition: 0.5s;
}

.text-muted-hover-danger {
  color: #97999b;
}

.text-muted-hover-danger:hover {
  color: #cc6262;
  transition: 0.5s;
}

.view-button {
  color: $risk-blue;
  background-color: white;
}

.rotate-90deg {
  transform: rotate(90deg);
}

.rotate-180deg {
  transform: rotate(180deg);
}

.outline-none {
  outline: none !important;
}

.overflow-y-auto-x-hidden {
  overflow-y: auto;
  overflow-x: hidden;
}

.hover-primary:hover {
  background-color: #4d7ef8 !important;
}

.bg-navy {
  background-color: $intus-navy;
}

.rounded-0-important {
  border-radius: 0 !important;
}

.rounded-lg-top {
  border-radius: 10px 10px 0px 0px;
}

.rounded-lg-bottom {
  border-radius: 0px 0px 10px 10px;
}

.disabled-muted:disabled {
  color: $intus-gray !important;
}

.parent-hover {
  .child-opacity {
    opacity: 0;
  }
}

.parent-hover:hover {
  .child-opacity {
    opacity: 1;
  }
}

.text-med-gray {
  color: #c4c4c4;
}

.text-med-navy {
  color: $intus-med-navy;
}

.bg-med-navy {
  background-color: $intus-med-navy;
}

.border-med-navy {
  border-color: $intus-med-navy !important;
}

.hover-intus-navy:hover {
  background-color: $intus-navy;
}

.hover-light-blue:hover {
  background-color: $light-blue-hover !important;
}

.hover-input-light-blue {
  outline: none !important;
  &:hover {
    background-color: #e3eafe !important;
  }

  &:focus {
    background-color: #e3eafe !important;
    border: 1px solid rgb(46, 98, 231) !important;
  }
}

.bg-light-blue {
  background-color: $light-blue-hover;
}

.icon-toggle {
  transition: 0.5s;
}

.icon-toggle-selected {
  background-color: white !important;
}

.shadow-inset {
  box-shadow: inset 0.5rem 0.5rem 1rem #d9d9d9, inset 0.5rem 0.5rem 1rem #0840cc;
}

.hover-text-secondary:hover {
  color: $intus-navy !important;
}

.hover-shadow-sm:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.8);
  // border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.flex-basis-0 {
  flex-basis: 0;
}
