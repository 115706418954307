.muuri {
  width: 100%;
}

.muuri-item {
  margin: 10px;
}

.muuri-item-dragging {
  border-radius: 10px;
  outline: #052d8f solid 2px;
}
