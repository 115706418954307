@import './Palette.scss';

.quad {
  width: 100%;
  background-color: $white;
}

.quad:hover {
  background-color: $off-white-blue;
}

.quad:focus {
  background-color: $off-white-blue;
}

.quad:active {
  background-color: $off-white-blue;
}

.non-clickable {
  border: none;
}

.ignore-content {
  flex-basis: 0;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.card {
  width: 100%;
  height: 100%;
}

.clickable {
  border-top: 3px hidden $transparent;
  border-bottom: 3px hidden $transparent;
  border-left: 3px hidden $transparent;
  border-right: 12px solid $risk-blue;
  transition: 0.25s ease;
  margin: 0;
  padding: 0;
  // padding: 3px 0px 3px 12px;
}

.clickable-disabled {
  border-top: 3px hidden $transparent;
  border-bottom: 3px hidden $transparent;
  border-left: 3px hidden $transparent;
  border-right: 12px solid $risk-gray;
  // padding: 3px 0px 3px 12px;
}

.clickable:hover {
  border-top: 3px hidden $transparent;
  border-bottom: 3px hidden $transparent;
  border-left: 3px hidden $transparent;
  border-right: 12px solid $intus-blue;
  // padding: 3px 0px 3px 12px;
  cursor: pointer;
}

.clickable:active {
  border-top: 3px hidden $transparent;
  border-bottom: 3px hidden $transparent;
  border-left: 3px hidden $transparent;
  border-right: 12px solid $intus-navy;
  // padding: 3px 0px 3px 12px;
}

.tableFixHead {
  overflow-y: auto;
  height: 100%;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px 16px;
}

th {
  background: rgb(255, 255, 255);
}

.search-dashboards {
  box-shadow: 0;
  transition: box-shadow 0.5s, background-color 0.5s;
  font-size: 0.9em;
}

.search-dashboards::placeholder {
  color: #c4c4c4;
}

.search-dashboards {
  input::placeholder {
    color: #c4c4c4;
  }
}

.search-dashboards:hover {
  background-color: #06329f !important;
  box-shadow: inset 0.5rem 0.5rem 1rem #052d8e, inset 0.5rem 0.5rem 1rem #0840cc;
}

.search-dashboards:focus {
  background-color: #06329f !important;
  box-shadow: inset 0.5rem 0.5rem 1rem #052d8e, inset 0.5rem 0.5rem 1rem #0840cc;
}

.dash-menu-option {
  background-color: #194bc3 !important;
  transition: 0.25s;
}

.dash-menu-option:active {
  background-color: $white !important;
  color: $intus-navy;
}

.dash-menu-option:hover {
  background-color: #1c5af6 !important;
}

.dash-menu-option:focus {
  background-color: #1c5af6 !important;
  color: $intus-navy;
}

.dash-menu-option-focus {
  background-color: $white !important;
  color: $intus-navy;
}

.dash-menu-title {
  // border-bottom: 1px solid transparent !important;
  border-left: 2px solid transparent !important;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.25s;
}

.dash-menu-title:hover {
  // border-bottom: 1px solid rgba(255, 255, 255, 0.8) !important;
  border-left: 2px solid rgba(255, 255, 255, 0.8) !important;
  color: rgba(255, 255, 255, 0.8);
}

.dash-menu-title-expanded {
  transition: 0.25s;
  // border-left: 2px solid white !important;
  color: rgba(255, 255, 255, 1);
  height: 2em;
}

.filter-buttons {
  // box-shadow: inset 0.1rem 0.1rem .5rem rgba(216, 223, 235, 0.9);
  border: 0.1px solid #e5e5e5;
  transition: all 0.25s ease-in-out;
  height: 2.3em;
}

.filter-buttons:hover {
  background-color: $light-blue-hover !important;
}

.disabled-filter-buttons {
  // box-shadow: inset 0.1rem 0.1rem .5rem rgba(216, 223, 235, 0.9);
  border: 0.1px solid #e5e5e5;
  transition: all 0.25s ease-in-out;
}
