@import '@intus-ui/styles/Palette.scss';
@import '@intus-ui/styles/IntusColors.scss';

.popover {
  min-width: 20%;
  max-width: 45%;
  min-height: 10%;
  max-height: 60%;
  overflow-y: auto;
  position: absolute;
  top: 275px;
  left: 800px;
  box-shadow: black;
}

.popover .arrow {
  display: none;
}

.popover-header {
  background-color: white;
  border-bottom: white;
}

.text-black {
  color: black;
}

.acuity-sidebar-item {
  outline: 1px solid $intus-gray;
  border: none !important;

  transition: none;
  color: $body;

  .text-dark {
    color: $body !important;
  }

  &.focused,
  &.hovered {
    outline: 2px solid $intus-blue !important;

    .condition-added {
      background-color: #d7e2fb;
    }
  }

  &.focused {
    background-color: #eff0f3;
  }

  .condition-change {
    padding-top: 0.15rem;
  }

  .condition-added {
    background-color: #e4ecff;
  }
}

.dropdown-menu {
  min-width: 5rem;
  max-width: 15rem;

  .form-check > input,
  .form-check > label {
    &:hover {
      cursor: pointer;
    }
  }
}
