.container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.card-container {
  flex-grow: 1;
  padding: 0rem 1rem 0rem 1rem;
}
