@import './Palette.scss';

.home-modal {
  .modal-content {
    background-color: $off-white-blue;
  }
}

.font-size-xs {
  font-size: small;
}

.home-tabs .nav-link.active,
.home-tabs .nav-tabs .nav-item.show .nav-link {
  border: 0;
}

.pin-item {
  border: 3px solid transparent;
  transition: 0.25s ease;

  &:hover {
    border: 3px solid $intus-navy-60;
  }
}
