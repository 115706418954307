//Text
$header: #052d8f;
$body: #404040;
$caption: #9d9d9d;
$disable: #c8c8c8;

//Highlight
$green: #4dc3ca;
$blue: #94c8f9;
$purple: #ca9deb;

//Background
$background: #ffffff;
$surface: #f6f7fa;
$surface-dark: #eff0f3;
$transparent: rgba(0, 0, 0, 0);

//Buttons
$primary: #052d8f;
$primary-hover: #00065f;
$secondary: #e4ecff;
$secondary-hover: #baceff;
$textButton: #2e62e7;
$textButton-hover: #052d8f;
$disabled-text: #282828;

//Risk
$highest-risk: rgb(204, 98, 98);
$high-risk: rgb(234, 197, 5);
$moderate-risk: rgb(25, 148, 187);
$low-risk: rgb(148, 200, 249);
$insuficient-data-risk: #c8c8c8;
$all-risk: #97999b;
