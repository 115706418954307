@import '@intus-ui/styles/IntusColors.scss';

.radio-label-wrapper {
  display: flex;
  direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}

.radio-wrapper {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: 'white';
  padding: 0px;
  margin-left: 0px;
}

.radio-wrapper-hover {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary;
  cursor: pointer;
  padding: 0px;
  margin-left: 0px;
}

.radio-outline-selected {
  border-radius: 50%;
  border: 0.1rem solid #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

.radio-outline-selected {
  border-radius: 50%;
  border: 0.1rem solid #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

.radio-outline-disabled {
  border-radius: 50%;
  border: 0.1rem solid $disable;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-fill-disabled {
  border-radius: 50%;
  background-color: white;
}

.radio-fill-hover {
  border-radius: 50%;
}

.radio-fill-selected {
  border-radius: 50%;
  background-color: $textButton;
}

.radio-label {
  color: #404040;
  font-size: calc(10px + 0.3rem);
  cursor: pointer;
}

.radio-label-disabled {
  color: $disable;
  font-size: calc(10px + 0.3rem);
  cursor: pointer;
}
