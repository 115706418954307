@import './Palette.scss';

.text-navy {
  color: $intus-navy;
}

.text-gray {
  color: $intus-gray;
}

.text-blue {
  color: $intus-blue;
}

.text-red {
  color: $red;
}

.text-red-hover:hover {
  color: $red-hover;
}

.red-border {
  // overrides bootstrap styling
  border-color: $red !important;
}

.blue-border {
  border-color: $intus-blue !important;
}

.rounded-corners {
  border-radius: 1rem;
}

.btn-none {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

.btn-none:focus {
  outline: none;
}

.hover-navy:hover {
  color: $intus-navy;
}

input:disabled {
  //overrides bootstrap styling
  background-color: $off-white-blue !important;
}

.button-red {
  //overrides bootstrap styling
  border-color: $red !important;
  background-color: $transparent !important;
  color: $red !important;
}

.button-red:hover {
  //overrides bootstrap styling
  border-color: $red-hover !important;
  background-color: $red-hover !important;
  color: white !important;
}

.dropdown-settings {
  .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    display: none;
  }
}

.dropdown-settings:hover {
  .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    display: block;
  }
}
