.inputComponent::placeholder {
  color: #9d9d9d;
}

.inputComponent:focus {
  outline: 0;
}

.textarea::placeholder {
  color: #9d9d9d;
}