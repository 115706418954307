.stat-bar- {
  background-color: #ebf6ff !important;
}

.stat-bar-:hover {
  background-color: #cee8ff !important;
}

.stat-bar-:active {
  background-color: #b2daff !important;
}
