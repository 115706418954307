@import './Palette.scss';

.profile-col {
  background-color: $intus-blue;
}

.stat-bar {
  background-color: $light-blue-t-300;
}

.stat-bar:hover {
  background-color: $light-blue-t-500;
}

.stat-bar:active {
  background-color: $light-blue-t-700 !important;
}

.accent {
  background-color: $intus-blue;
  width: 0.4rem;
  min-width: 0.4rem;
}

.subscore-header {
  background-color: $intus-navy-t-40;
}

.subscore-header-full {
  background-color: $intus-navy;
}

// The white background is for partially transparent images.
.profile-picture {
  background-color: white;
}

.acuity-select {
  height: 4rem;
  background-color: $intus-blue;
  border: 1px solid white;
}

.acuity-select:hover {
  background-color: $risk-blue-4;
  border: 3px solid white;
  font-weight: bold;
}

.acuity-select:not(:disabled):not(.disabled):active,
.acuity-select:not(:disabled):not(.disabled).active,
.show > .acuity-select.dropdown-toggle {
  background-color: $risk-blue-9;
  border: 3px solid white;
  font-weight: bold;
}

.detail {
  color: rgba(255, 255, 255, 0.8);
}

.border-soft {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.one-column {
  column-count: 1 !important;
}

.overflow-scroll {
  overflow-y: auto;
}

.quad {
  width: 100%;
  height: 90%;
}

.acuity-nav:hover {
  color: $intus-navy !important;
  text-decoration: none !important;
  outline: none;
}

.acuity-nav:focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none;
}

.acuity-nav:active {
  color: $intus-navy !important;
  text-decoration: none !important;
  font-weight: bold !important;
  outline: none !important;
  box-shadow: none;
}

.acuity-nav-focus {
  color: $intus-navy !important;
  text-decoration: none !important;
  font-weight: bold !important;
  background-color: #f6f7fa;
  border-radius: 8px;
  outline: none !important;
  box-shadow: none;
}

.acuity-nav::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.transparent-border {
  border: 2px solid transparent;
}

.past-acuity-card:focus {
  background-color: white !important;
  outline: none;
}

.past-acuity-card:hover:not(:focus) {
  background-color: white !important;
  border: 2px solid $intus-blue-30;
}

.table-fixed thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

/*This will work on every browser*/
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.barchart {
  background-color: $off-white-blue;
}

.care-plan-complete {
  color: $intus-blue;
  border: 1px solid $intus-blue;
}

.care-plan-incomplete {
  color: $risk-yellow;
  border: 1px solid $risk-yellow;
}

.care-plan-row-border-top {
  border-top: 1px solid $intus-gray;
}

.icon-direction {
  background-color: transparent;
  color: $intus-blue;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.intus-blue {
  color: $intus-blue;
}

.intus-navy {
  color: $intus-navy;
}

.tooltip-border {
  border-top: 3px solid $intus-blue;
  border-bottom: 3px solid $intus-blue;
  border-left: 3px solid $intus-blue;
  border-right: 3px solid $intus-blue;
}

.base-white {
  color: $base-color;
}

.icon-margin-top {
  margin-top: -3px;
}

.falls-notes-dropdown-button {
  background: none;
  border: none;
  padding: 0;
}

.falls-notes-dropdown-button:hover {
  color: none;
}

.falls-notes-dropdown-button:active {
  outline: none;
}
