.noInteraction {    
    border: 1px solid #9D9D9D,
}

.dropshadowCard {
    border-top: 2px hidden rgba(0,0,0,0);
    border-bottom: 2px hidden rgba(0,0,0,0);
    border-left: 2px hidden rgba(0,0,0,0);
    border-right: 8px solid #BACEFF;
    transition: 0.25s ease;
    outline: 1px solid rgba(0,0,0,0);
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.dropshadowCard:disabled {
    border-top: 2px hidden rgba(0,0,0,0);
    border-bottom: 2px hidden rgba(0,0,0,0);
    border-left: 2px hidden rgba(0,0,0,0);
    border-right: 8px solid #C8C8C8;

}

.dropshadowCard:hover {
    border-top: 2px hidden rgba(0,0,0,0);
    border-bottom: 2px hidden rgba(0,0,0,0);
    border-left: 2px hidden rgba(0,0,0,0);
    border-right: 8px solid #2E62E7;
    background-color: #EFF0F3;
}

.dropshadowCard:focus {
    border-top: 2px hidden rgba(0,0,0,0);
    border-bottom: 2px hidden rgba(0,0,0,0);
    border-left: 2px hidden rgba(0,0,0,0);
    border-right: 8px solid #2E62E7;
    background-color: #EFF0F3;
    outline: 1px solid #2E62E7;
}

.dropshadowCard:active {
    border-top: 2px hidden rgba(0,0,0,0);
    border-bottom: 2px hidden rgba(0,0,0,0);
    border-left: 2px hidden rgba(0,0,0,0);
    border-right: 8px solid #BACEFF;
    outline: 1px solid rgba(0,0,0,0);
}

.flatCardNonClickable {
    border: 1px solid #9D9D9D;
    cursor: auto;
}

.flatCard {
    cursor: pointer;
    background-color: #ffffff;
    transition: 0.25s ease;
    border: 1px solid #9D9D9D,
}

.flatCard:hover {
    background-color: #EFF0F3;
    border: 1px solid #9D9D9D,
}


.flatCard:focus {
    border: 1px solid #2E62E7;
}

.flatCard:active {
    border: 1px solid #9D9D9D,
}