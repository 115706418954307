@import '@intus-ui/styles/Palette.scss';

// spacing is intentional, admit filters don't use a space, facility/org do
#admitTimeFilter.dropdown,
#admitTypeFilter.dropdown,
#facilityFilter .dropdown,
#orgFilter .dropdown {
  max-width: 251px !important; // to account for border

  &.show .filter-buttons {
    background-color: $light-blue-hover !important;
  }

  .dropdown-title-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-menu {
    min-width: 100% !important;
    max-width: 251px !important;
    width: max-content !important;
    max-height: 17.25rem !important;
    overflow-y: auto !important;
    margin-top: -1px !important;
  }

  .dropdown-item {
    white-space: break-spaces !important;
  }
}
