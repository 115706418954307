.submit-button {
  font-family: "Inter", sans-serif;
  padding: 1rem;
  border-radius: 10px;
  background-color: #2e62e7;
  border-width: 0px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  transition: 50ms;
  margin: auto;
  display: block;
}

.editing-buttons {
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.delete {
  margin: 0 0.5rem 0 0 !important;
  background-color: #cd3b3b !important;
  height: 2rem;
  padding: 0 !important;
  width: 4.5rem;
}

.delete:hover {
  background-color: #e56565 !important;
}

.cancel {
  margin: 0 0.25rem 0 0.25rem !important;
  background-color: #eac505 !important;
  height: 2rem;
  padding: 0 !important;
  width: 4.5rem;
}

.cancel:hover {
  background-color: #f9e687 !important;
}

.save {
  margin: 0 0 0 0.5rem !important;
  height: 2rem;
  width: 4rem;
  padding: 0 !important;
}

.submit-button:hover {
  background-color: #6b92f4;
}

.submit-button:focus {
  outline: none;
  background-color: #6b92f4;
}

textarea {
  resize: none;
  width: 100%;
  height: 10rem;
  font-family: "Inter", sans-serif;
  transition: 100ms;
  margin-bottom: 1rem;
}

textarea:focus {
  /* border-width: 0px; */
  border-color: #2e62e7;
  /* outline-color: #2e62e7; */
  outline: none !important;
  /* border: 1px solid red; */
  box-shadow: 0 0 10px lightGrey;
}
