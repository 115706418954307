.dd-list {
  -webkit-box-shadow: 1px 4px 9px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 4px 9px 2px rgba(0, 0, 0, 0.08);
  overflow-y: auto;
}

.dd-item:hover {
  background-color: #e3eafe;
  cursor: pointer;
}
